.fm-home {
  height: 500px;
}

.fm-home .fm-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fm-home .fm-loading {
  min-height: 300px;
  min-height: 70vh;
}
